import { css } from '@emotion/react';
import localFont from 'next/font/local';
import Image from 'next/image';
import React from 'react';

import {
  appendSearchQueryParamsToDisloyaltyUrl,
  getDisloyaltyBannerConfig,
  transformHotelName,
} from '@/availability/components/banners/disloyalty-banner.helpers';
import { useAvailabilityURLQuery } from '@/availability/hooks';
import { Box } from '@/box';
import { useHotel } from '@/hotel';
import { BodySmall, Caption, Text } from '@/ui/typography';

const disloyaltyFont = localFont({
  src: '../../../../../public/disloyalty/fonts/pp-right-slab-light.otf',
  display: 'swap',
});

export function DisloyaltyBanner({ hotelId }: { hotelId: string }) {
  const config = getDisloyaltyBannerConfig(hotelId);
  const hotel = useHotel(hotelId);

  const query = useAvailabilityURLQuery();

  if (!config || !hotel) return null;

  const disloyaltyUrl = appendSearchQueryParamsToDisloyaltyUrl(
    config.link,
    query
  );

  return (
    <Box
      display={'flex'}
      position={'relative'}
      flexDirection={['column', 'row']}
      justifyContent={['start', 'space-between']}
      alignItems={'center'}
      backgroundColor={'#c5ff98'}
      height={['auto', 208]}
      padding={['40px 30px 80px', '0 20px 0', '0 40px 0']}
      gap={[24, 16, 32]}
      css={css`
        color: #3d3937;
      `}
    >
      <Box
        display={['flex']}
        justifyContent={'center'}
        alignItems={'center'}
        width={78}
        height={78}
        css={css`
          border: 1px solid #143c21;
          border-radius: 50%;
        `}
      >
        <Image
          src="/disloyalty/disloyalty-logo.svg"
          width={30}
          height={33}
          alt="Disloyalty logo"
        />
      </Box>

      <Box marginRight={[0, 'auto']} textAlign={['center', 'left']}>
        <div className={`${disloyaltyFont.className}`}>
          <Text style="heading5" uppercase fontFamily="inherit" inheritColor>
            {'Want '}
            <u>{config.justOpened ? 'up to 50% off' : '20% off'}</u>
            {' your stay at'}
          </Text>
          <Text style="heading5" fontFamily="inherit" inheritColor>
            {transformHotelName(hotel.name, hotel.brandReferenceId)}
            {'?'}
          </Text>
        </div>
      </Box>
      <Box height={[40, 57]}>
        <a
          href={disloyaltyUrl}
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: black;
            height: 100%;
            padding-right: 17px;
            padding-left: 17px;
            color: #edeadd;
          `}
          rel="noopener noreferrer"
          target="_blank"
        >
          <BodySmall uppercase noWrap inheritColor>
            {'Join Dis-Loyalty'}
          </BodySmall>
        </a>
      </Box>
      <Box
        position={'absolute'}
        width={'full'}
        css={css`
          bottom: 0;
          left: 0;
        `}
        padding={['0 40px 12px', '0 24px 18px']}
      >
        <Caption
          css={css`
            text-align: center;
          `}
          inheritColor
        >
          {
            'Booking must be made at least 7 days in advance. Discount cannot be used in conjunction with any other offers. T&Cs apply.'
          }
        </Caption>
      </Box>
    </Box>
  );
}

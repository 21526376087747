import { EnnismoreTheme } from '@/ui/theme/ennismore-theme.interface';

const fonts = {
  headline: 'mabgate, serif',
  intro: 'mabgate, sans-serif',
  subheader: 'mabgate, sans-serif',
  body: '"aktiv-grotesk", sans-serif',
};

export const morgansOriginalsTheme: EnnismoreTheme = {
  themeKey: 'morgansoriginals',
  logoUrl: '/brands/morgansoriginals/images/morgans-logo-stacked.svg',
  faviconUrl: '/brands/morgansoriginals/images/favicon.svg',
  space: ['0px', '8px', '16px', '20px', '24px', '32px', '40px', '56px'],
  fonts,
  text: {
    heading1: {
      fontFamily: fonts.headline,
      fontWeight: 400,
      fontSize: '32px',
      lineHeight: '56px',
      textTransform: 'uppercase',
    },
    heading2: {
      fontFamily: fonts.subheader,
      fontWeight: 400,
      fontSize: '56px',
      lineHeight: '64px',
      textTransform: 'uppercase',
    },
    heading3: {
      fontFamily: fonts.subheader,
      fontSize: '40px',
      lineHeight: '48px',
      textTransform: 'uppercase',
    },
    heading4: {
      fontFamily: fonts.subheader,
      fontSize: '32px',
      lineHeight: '40px',
      textTransform: 'uppercase',
    },
    heading5: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '32px',
      textTransform: 'uppercase',
    },
    heading6: {
      fontFamily: fonts.subheader,
      fontSize: '18px',
      lineHeight: '24px',
    },
    intro1: {
      fontFamily: fonts.intro,
      fontSize: '40px',
      lineHeight: '56px',
    },
    intro2: {
      fontFamily: fonts.intro,
      fontSize: '24px',
      lineHeight: '40px',
    },
    subheader1: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '32px',
    },
    subheader2: {
      fontFamily: fonts.subheader,
      fontSize: '18px',
      lineHeight: '32px',
    },
    subheader3: {
      fontFamily: fonts.subheader,
      fontSize: '14px',
      lineHeight: '24px',
    },
    bodyLarge: {
      fontFamily: fonts.body,
      fontSize: '20px',
      lineHeight: '32px',
    },
    bodyCopy: {
      fontFamily: fonts.body,
      fontSize: '18px',
      lineHeight: '28px',
    },
    bodySmall: {
      fontFamily: fonts.body,
      fontSize: '16px',
      lineHeight: '24px',
    },
    captions: {
      fontFamily: fonts.body,
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  colors: {
    white: 'white',
    textPrimary: '#fff',
    textSecondary: '#ccc',
    background: '#000',
    primary100: '#333', // glen only - ignore
    primary200: '#000',
    primary300: '#2d2926',
    primary400: '#FFE7D9', // hox only
    primary500: '#2d2926',
    secondary100: '#000',
    secondary200: '#d9d9d9',
    secondary300: '#2d2926',
    secondary400: '#2d2926',
    error: '#DD3739',
  },
  buttons: {
    primary: {
      backgroundColor: '#2350E9',
      fontFamily: fonts.body,
      fontWeight: 500,
      fontSize: '16px',
      borderRadius: '26px',
      color: 'white',
      textTransform: 'uppercase',
      ':hover': {},
    },
    secondary: {
      backgroundColor: 'transparent',
      fontFamily: fonts.body,
      fontWeight: 500,
      fontSize: '16px',
      borderRadius: '26px',
      color: '#fff',
      border: '1px solid #fff',
      textTransform: 'uppercase',
      ':hover': {},
    },
    destructive: {
      backgroundColor: '#DD3739',
      fontFamily: fonts.body,
      fontWeight: 500,
      fontSize: '16px',
      borderRadius: '26px',
      color: 'white',
      // border: '1px solid white',
      textTransform: 'uppercase',
      mixBlendMode: 'difference',
      ':hover': {},
    },
  },
  keylines: {
    border: '1px solid white',
  },
  forms: {
    heading: {
      fontFamily: fonts.body,
    },
    label: {
      color: 'white',
    },
    input: {
      backgroundColor: 'white',
      color: 'black',
      fontFamily: fonts.body,
      fontSize: '16px',
    },
    radioButton: {
      color: 'black',
      selected: {
        backgroundColor: '#d9d9d980',
      },
    },
    radioGroup: {
      divider: { border: '1px solid #ccc' },
    },
    stripe: {
      text: {
        color: 'white',
      },
      tab: {
        border: '1px solid #757575',
        color: '#757575',
        selected: {
          border: '1px solid white',
          color: 'white',
        },
      },
    },
  },
  fontFaceDefinitions: [
    {
      fontFamily: 'mabgate',
      fontWeight: 400,
      sources: {
        woff2: '/brands/morgansoriginals/fonts/mabgate-regular.woff2',
      },
    },
    {
      fontFamily: 'aktiv-grotesk',
      fontWeight: 300,
      sources: {
        woff2:
          'https://use.typekit.net/af/63d81f/000000000000000077359d5a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3',
      },
    },
    {
      fontFamily: 'aktiv-grotesk',
      fontWeight: 400,
      sources: {
        woff2:
          'https://use.typekit.net/af/ab3e12/000000000000000077359d4f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3',
      },
    },
  ],
  componentProperties: {
    search: {
      results: {
        roomSpecColumns: 2,
        sidebarContainer: {
          rowGap: 'none',
          cancellationPolicy: {
            borderBottom: 'none',
          },
          hotelPerks: {
            borderLeft: 'none',
            borderRight: 'none',
          },
        },
      },
      collapsibleForm: {
        searchSummary: {
          backgroundColor: 'black',
          border: '1px solid white',
          borderLeft: '1px solid black',
          borderRight: '1px solid black',
          fontFamily: 'aktiv-grotesk, sans-serif',
          color: 'white',
          textTransform: 'none',
          modifySearchButton: {
            textTransform: 'uppercase',
          },
          expanded: {
            borderLeft: '1px solid white',
            borderRight: '1px solid white',
            borderBottom: 'none',
          },
          mobile: {
            padding: '28px 0',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
            marginTop: '24px',
          },
        },
        searchForm: {
          backgroundColor: 'black',
          color: 'white',
          border: '1px solid white',
          borderTop: 'none',
        },
      },
      errorCard: {
        mobile: {
          border: '1px solid white',
          margin: '0 28px',
        },
      },
    },
    calendar: {
      header: {
        borderRadius: '3px 3px 0 0',
        caption: {
          fontFamily: fonts.body,
          fontWeight: 500,
        },
        arrow: {
          stroke: 'none',
        },
      },
      body: {
        date: {
          selected: {
            backgroundColor: '#2350E9',
          },
        },
      },
    },
    paymentMethods: {
      googlePay: {
        style: 'light',
      },
      applePay: {
        style: 'light',
      },
    },
    sidebarCards: {
      primary: {
        borderRadius: '0px',
        padding: 'none',
        backgroundColor: 'primary200',
        borderColor: 'primary100',
        color: 'textPrimary',
        borderLeft: 'none',
        borderRight: 'none',
        paddingTop: '20px',
        paddingBottom: '20px',
      },
      secondary: {
        borderRadius: '0px',
        padding: 'none',
        backgroundColor: 'background',
        borderColor: 'primary100',
        color: 'textPrimary',
        borderLeft: 'none',
        borderRight: 'none',
        paddingTop: '20px',
        paddingBottom: '20px',
      },
    },
    banners: {
      backgroundColor: 'primary200',
      color: 'textSecondary',
    },
    checkoutSidebar: {
      rowGap: 'none',
      cancellationPolicy: {
        borderTop: 'none',
      },
    },
    cards: {
      primary: {
        border: '1px solid white',
        backgroundColor: 'black',
        color: 'white',
        divider: {
          borderColor: '#ccc',
        },
      },
    },
    modals: {
      color: 'black',
      backgroundColor: 'white',
    },
    page: {
      background: 'black',
    },
    bookingManagement: {
      pageWrapper: {
        login: { alignContent: 'left' },
        verify: { alignContent: 'left' },
      },
      bookingCard: {
        header: {
          borderBottom: '1px solid white',
        },
        footer: {
          borderTop: '1px solid white',
        },
        backgroundColor: 'black',
        cancelled: {
          opacity: 1,
        },
      },
    },
    confirmation: {
      rowGap: 'none',
      headerImage: {
        margin: '0',
        width: '100%',
        height: '480px',
        paddingBottom: '48px',
        objectFit: 'cover',
      },
      bookingConfirmationContainer: {
        backgroundColor: 'black',
        border: '1px solid white',
        borderLeft: 'none',
        borderRight: 'none',
        padding: '40px 0px',
        margin: '0px 28px',
        mobile: {
          padding: '28px 0',
          margin: '0 28px',
        },
      },
      anyQuestionsCard: {
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        padding: '40px 0px',
        margin: '0px 28px',
        mobile: {
          margin: '0 28px',
          padding: '28px 0',
        },
      },
      restaurantsCard: {
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        padding: '40px 0px',
        margin: '0px 28px',
        mobile: {
          margin: '0 28px',
          padding: '28px 0',
        },
      },
      whereToFindUsCard: {
        borderTop: 'none',
        borderLeft: 'none',
        borderBottom: 'none',
        borderRight: 'none',
        padding: '40px 0px',
        margin: '0px 28px',
        planYourJourneyLink: {
          color: 'white',
        },
        mobile: {
          margin: '0 28px',
          padding: '28px 0',
        },
      },
      bookingNumberHeading: {
        fontFamily: fonts.body,
      },
    },
    sideDrawer: {
      backgroundColor: 'black',
      closeButton: {
        color: 'white',
      },
    },
    paymentPendingPage: {
      color: 'white',
    },
    transportOptions: {
      icon: {
        filter: 'brightness(0) invert(1) brightness(2) contrast(100)',
      },
    },
    hotelPerk: {
      icon: {
        filter: 'brightness(0) invert(1) brightness(2) contrast(100)',
      },
    },
    halfScreenLayout: {
      borderRight: 'none',
    },
    staySummary: {
      borderLeft: 'none',
      borderRight: 'none',
      backgroundColor: 'black',
      summarySection: {
        backgroundColor: 'black',
      },
      color: 'white',
      padding: '0',
      total: { value: { fontWeight: 500 } },
      title: {
        textTransform: 'uppercase',
        padding: '8px 0 8px',
      },
      dataRow: {
        label: {
          opacity: 1,
        },
      },
      dropdown: {
        opacity: 1,
        textTransform: 'uppercase',
        infoIcon: {
          filter: 'brightness(0) invert(1) brightness(2) contrast(100)',
        },
      },
    },
    bedroomCard: {
      backgroundColor: 'white',
      color: 'black',
      border: 'none',
      roomSpecItem: {
        color: '#757575',
        value: {
          color: 'black',
          fontSize: '16px',
        },
        icon: {
          filter: 'grayscale(100%) brightness(150%) contrast(90%)',
        },
      },
    },
    header: {
      logo: {
        borderRight: 'none',
        left: '80px',
        padding: '30px',
      },
      wizard: {
        step: {
          fontWeight: 300,
          active: {
            fontWeight: 500,
          },
          textTransform: 'uppercase',
        },
      },
    },
    footer: {
      color: 'white',
    },
  },
  icons: {
    BULLET: '/brands/gleneagles/icons/diamond-bullet.svg',

    TRAIN: '/brands/hoxton/icons/transport/train.svg',
    PLANE: '/brands/hoxton/icons/transport/plane.svg',

    SIZE: '/brands/hoxton/icons/room-icons/room-size-icon.svg',
    BED_SIZE: '/brands/hoxton/icons/room-icons/bed-size-icon.svg',
    CAPACITY: '/brands/hoxton/icons/room-icons/room-capacity.svg',
    DOG_FRIENDLY: '/brands/hoxton/icons/hox-hounds.svg',
    ROOM_WITH_VIEW: '/brands/hoxton/icons/room-icons/room-view-icon.svg',

    SNOOZE: '/brands/hoxton/icons/late-snooze.svg',
    WIFI: '/brands/hoxton/icons/wifi.svg',
    BREAKFAST: '/brands/hoxton/icons/breakfast-bag.svg',
    SWEET: '/brands/hoxton/icons/sweet.svg',
    DOG: '/brands/hoxton/icons/hox-hounds.svg',
    ADA: '/brands/hoxton/icons/accessibility.svg',
    GYM: '/brands/hoxton/icons/gym.png',
  },
  __temporary: {
    displayCarTransportOnNewLine: true,
  },
};

import { css, keyframes } from '@emotion/react';
import Link, { LinkProps } from 'next/link';
import React from 'react';

import { LoadingIndicator } from '@/ui/controls';

import { EnnismoreTheme, useTheme } from '../theme';

/**
 * NOTE: These button variants do nothing. Currently they're used only by the My Account / Amend booking flows.
 * We're removing the variant styles themselves with an expectation that we'll re-introduce them with
 * the new theming system.
 */
type ButtonVariant = 'primary' | 'secondary' | 'destructive';

export const buttonStyles = ({
  buttons,
  colors,
  variant,
}: {
  buttons: EnnismoreTheme['buttons'];
  colors: EnnismoreTheme['colors'];
  variant: ButtonVariant;
}) => {
  const styles: EnnismoreTheme['buttons']['primary'] =
    buttons[variant] || buttons.primary;
  return css({
    fontFamily: styles.fontFamily,
    appearance: 'none',
    display: 'inline-block',
    textAlign: 'center',
    textDecoration: 'none',
    fontSize: styles.fontSize || '16px',
    lineHeight: styles.lineHeight || '24px',
    fontWeight: styles.fontWeight || 'bold',
    padding: '12px 20px',
    borderRadius:
      typeof styles.borderRadius === 'undefined' ? 4 : styles.borderRadius,
    transition: styles.transition ?? 'opacity ease .1s',
    cursor: 'pointer',
    position: 'relative',
    width: '100%',
    color: styles.color || colors.white,
    backgroundColor: styles.backgroundColor,
    textTransform: styles.textTransform,
    touchAction: 'manipulation',
    letterSpacing: styles.letterSpacing,
    border: styles.border,
    mixBlendMode: styles.mixBlendMode,

    ':hover': styles[':hover'] ? styles[':hover'] : undefined,

    '&:hover:not(:disabled)': {
      opacity: styles.hoverOpacity || 0.85,
    },

    '&:active:not(:disabled)': {
      opacity: 1.0,
    },

    '&:disabled': {
      opacity: styles.disabledOpacity || 0.6,
      cursor: 'not-allowed',
    },

    '&.focus-visible': {
      outline: `1px solid ${colors.secondary300}`,
      outlineOffset: 4,
    },
  });
};

export interface ButtonProps {
  children: string;
  onClick?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  variant?: ButtonVariant;
  testId?: string;
}

export const Button = ({
  children,
  onClick,
  isDisabled,
  isLoading,
  type,
  variant = 'primary',
  testId,
}: ButtonProps) => {
  const { colors, buttons, themeKey } = useTheme();

  if (themeKey === 'morgansoriginals' && buttons.primary[':hover']) {
    buttons.primary[':hover'].animation = `${keyframes`
    0 {
      background-color: #2350e9;
    }
    20% {
      background-color: #c9d34e;
    }
    40% {
      background-color: #44955d;
    }
    60% {
      background-color: #d86340;
    }
    80% {
      background-color: #2350e9;
    }
    `} 3s infinite`;
  }

  return (
    <button
      css={buttonStyles({ buttons, colors, variant })}
      onClick={isDisabled ? undefined : onClick}
      disabled={isDisabled || isLoading}
      type={type ?? 'button'}
      aria-label={children}
      data-test={testId}
    >
      {isLoading && (
        <div
          css={css({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          })}
        >
          <LoadingIndicator width={25} />
        </div>
      )}

      <div
        css={css({
          visibility: isLoading ? 'hidden' : 'visible',
        })}
      >
        {children}
      </div>
    </button>
  );
};

type SubmitButtonProps = Omit<ButtonProps, 'onClick' | 'type'>;
export const SubmitButton: React.FC<SubmitButtonProps> = (props) => (
  // Shoehorning this in a bit here.
  // Obviously passing an onClick handler isn't really appropriate for a button with type=submit, but I want to keep it required unless we use a specific component like this.
  <Button onClick={null as any} type="submit" {...props} />
);

export interface ButtonLinkProps extends React.PropsWithChildren<LinkProps> {}
export const ButtonLink: React.FC<ButtonLinkProps> = ({
  children,
  ...props
}) => {
  const { buttons, colors } = useTheme();
  return (
    <Link
      {...props}
      css={buttonStyles({ buttons, colors, variant: 'primary' })}
    >
      {children}
    </Link>
  );
};

export default Button;

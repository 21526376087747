import type {
  V1BookingRoom,
  V1GuestDetail,
} from '@ennismore/booking-api-client';
import * as React from 'react';
import { useCallback } from 'react';

import { trackNoAvailabilityEvent } from '@/analytics/analytics';
import { trackBookingSearchEventInGTM } from '@/analytics/gtm';
import { createBookingSearchAnalyticsLabel } from '@/analytics/helpers/create-booking-analytics-label.helper';
import type { V1RoomOccupancySchema } from '@/api/clients/ohip';
import { HotelAvailabilitySearchResults } from '@/availability/components/HotelAvailabilitySearchResults.component';
import { useCreateBookingBasket } from '@/booking-creation/hooks/use-create-booking-basket.hook';
import { AmendBookingError } from '@/booking-management/errors/amend-booking.error';
import { useDisloyaltyDummyRateFlagTracking } from '@/booking/components/disloyalty-promotional-rate/hooks';
import type { CreateBookingError } from '@/booking/errors';
import {
  selectHotelConfigurationByCodeOrFail,
  selectHotelConfigurationByReferenceIdOrFail,
  useActiveBrandConfig,
} from '@/brand';
import { getLastItem } from '@/common/utils/array';
import { useErrorTranslation } from '@/errors';
import { useAppEvents } from '@/events';
import { getBookingGuestFromCount, useHotel } from '@/hotel';
import { BedroomCard } from '@/hotel/components';
import { useLocale, useTranslation } from '@/i18n';
import { captureException } from '@/logging';
import { Stack } from '@/ui/spacing';
import { useTheme } from '@/ui/theme';

import { AvailabilitySearchResultsBannersContainer } from '.';
import {
  FullHouseNoAvailabilityCard,
  NoAccessibleRoomsAvailableCard,
} from '../components';
import { NearbyAvailableHotels } from '../components/NearbyAvailableHotels.component';
import { AvailabilityEvents } from '../events';
import { navigateToAvailabilityResultsPage } from '../helpers';
import {
  formatRoomAvailabilityAsGTMProduct,
  formatSearchQueryAsGTMBooking,
} from '../helpers/analytics';
import { useRoomImpressionTracking } from '../hooks';
import { IAvailabilitySearchResultsModelInstance } from '../models';
import {
  IAvailabilitySearchQueryInstance,
  availabilitySearchQuerySelectors,
} from '../models/availability-search-query.model';
import { IRoomAvailabilityInstance } from '../models/room-availability.model';

interface BedroomAvailabilityListProps {
  query: IAvailabilitySearchQueryInstance;
  results: IAvailabilitySearchResultsModelInstance;
  onRequestModifySearch: () => void;

  onRoomSelectionComplete: (rooms: V1BookingRoom[]) => void;

  createBookingError?: CreateBookingError | AmendBookingError;
  isCreatingBooking?: boolean;

  basket: ReturnType<typeof useCreateBookingBasket>;

  onRequestDateRangeChange: (start: string, end: string) => void;
}

/**
 * This is vast and horrible. What a mess. Will cleanup in the hopefully imminent rebuild.
 * @param props
 */
export const BedroomAvailabilityList = ({
  results,
  query,
  basket,
  ...props
}: BedroomAvailabilityListProps) => {
  const locale = useLocale();
  const { t } = useTranslation();
  const getErrorTranslation = useErrorTranslation();
  const brandConfig = useActiveBrandConfig();
  const hotelConfig = selectHotelConfigurationByCodeOrFail(
    brandConfig,
    query.hotelCode
  );
  const hotel = useHotel(hotelConfig.referenceId);
  const querySelectors = availabilitySearchQuerySelectors(query);
  const { componentProperties } = useTheme();

  const selectingAccessibleRoom =
    query.rooms[basket.selectedRooms.length].accessible;

  const rooms = selectingAccessibleRoom
    ? results.accessibleRooms
    : results.filteredRooms;

  const handleRequestSwitchHotelSearch = useCallback(
    (slug: string) => {
      const hotel = selectHotelConfigurationByReferenceIdOrFail(
        brandConfig,
        slug
      );

      return navigateToAvailabilityResultsPage({
        ...querySelectors.asURLQuery(),
        hotelCode: hotel.code,
      });
    },
    [query, brandConfig]
  );

  // Analytics
  const events = useAppEvents<AvailabilityEvents>();

  /**
   * If the user is selecting multiple rooms, only show the error for the last room.
   */
  const shouldShowErrorForRoom = useCallback(
    (roomCode: string) => {
      if (!basket.isMultiRoomSearch) return true;

      return getLastItem(basket.selectedRooms)?.roomCode === roomCode;
    },
    [basket.selectedRooms, basket.isMultiRoomSearch]
  );

  useRoomImpressionTracking({ hotel, availability: rooms });
  useDisloyaltyDummyRateFlagTracking({ hotelReferenceId: hotel?.referenceId });

  React.useEffect(() => {
    if (!results || !hotel) {
      return;
    }

    if (results.isHotelFullyBooked) {
      trackNoAvailabilityEvent(
        createBookingSearchAnalyticsLabel({
          query: query,
          hotelReferenceId: hotelConfig.referenceId,
        })
      );
    }

    // Format room results as 'product impressions' for GTM
    try {
      const productImpressions = results.rooms.map((availability) =>
        formatRoomAvailabilityAsGTMProduct(availability)
      );
      const booking = formatSearchQueryAsGTMBooking(
        query,
        brandConfig.chainCode,
        hotel
      );

      trackBookingSearchEventInGTM({
        booking,
        productImpressions,
      });
    } catch (e) {
      captureException(e);
    }
  }, [results, results.isHotelFullyBooked]);

  if (selectingAccessibleRoom && rooms.length === 0) {
    return (
      <Stack s="xl">
        <NoAccessibleRoomsAvailableCard
          onModifySearchRequested={props.onRequestModifySearch}
        />
        <NearbyAvailableHotels
          checkInDate={query.from}
          checkOutDate={query.to}
          hotelReferenceId={hotelConfig.referenceId}
          guests={basket.nextRoomToSelect as V1RoomOccupancySchema}
          onRequestSwitchHotelSearch={handleRequestSwitchHotelSearch}
          rateCodes={query.rateCode ? [query.rateCode] : undefined}
          locale={{ language: locale.baseName }}
          numberOfRooms={1}
        />
      </Stack>
    );
  }

  if (results.isHotelFullyBooked) {
    return (
      <Stack s="xl">
        <FullHouseNoAvailabilityCard
          onModifySearchRequested={props.onRequestModifySearch}
        />
        <NearbyAvailableHotels
          checkInDate={query.from}
          checkOutDate={query.to}
          hotelReferenceId={hotelConfig.referenceId}
          guests={basket.nextRoomToSelect as V1RoomOccupancySchema}
          onRequestSwitchHotelSearch={handleRequestSwitchHotelSearch}
          rateCodes={query.rateCode ? [query.rateCode] : undefined}
          locale={{ language: locale.baseName }}
          numberOfRooms={1}
        />
      </Stack>
    );
  }

  // Fired when the rate code is un-switchable (eg. user is selecting room 2 of a multi-room booking),
  // User clicks "reset"
  const handleRequestRoomSelectionReset = () => {
    basket.reset();
    window.scrollTo({ top: 0, left: 0 });
  };

  if (!query || !results || !hotel) {
    return null;
  }

  return (
    <HotelAvailabilitySearchResults
      title={
        // Only display the title if the user has requested more than 1 room
        basket.isMultiRoomSearch
          ? `Room ${basket.nextRoomNumberToSelect}`
          : undefined
      }
      rateCodeSearchErrorMessage={results.metaData?.invalidRateCodeMessage}
      resultRoomCount={results.rooms.length}
    >
      <Stack
        s={componentProperties.search.results.bedroomAvailabilityList?.rowGap}
      >
        <AvailabilitySearchResultsBannersContainer
          results={results}
          hotelSlug={hotel.referenceId}
        />
        {rooms.map((availability: IRoomAvailabilityInstance, index) => {
          return (
            <React.Fragment key={index + availability.room.name}>
              <BedroomCard
                roomAvailabilityModel={availability}
                handleRequestRoomSelectionReset={
                  handleRequestRoomSelectionReset
                }
                hotelModel={hotel}
                isCreatingBooking={props.isCreatingBooking}
                ctaText={
                  basket.isMultiRoomSearch
                    ? t('search:roomCard.selectRoomNumber', {
                        roomNumber: basket.nextRoomNumberToSelect,
                      })
                    : undefined
                }
                onCtaClick={({
                  // TODO: handle unused selectedInternalRateCode and isTwinSelected
                  selectedInternalRateCode,
                  selectedRequestRateCode,
                  isTwinSelected,
                }) => {
                  const roomSelection = {
                    checkInDate: query.from,
                    checkOutDate: query.to || query.from,
                    roomCode: availability.room.code,
                    rateCode:
                      selectedInternalRateCode || selectedRequestRateCode,
                    internalRateCode: selectedInternalRateCode,
                    requestRateCode: selectedRequestRateCode,
                    guests: basket.nextRoomToSelect as V1GuestDetail,
                    numberOfRooms: 1,
                  };

                  events.emit('selectRoom', {
                    room: roomSelection,
                    roomIndex: basket.nextRoomNumberToSelect - 1,
                    availability,
                    hotel,
                  });

                  if (basket.isFinalRoomToSelect) {
                    props.onRoomSelectionComplete([
                      ...basket.selectedRooms,
                      roomSelection,
                    ]);
                  } else {
                    basket.addRoom(roomSelection);
                    // TODO: not ideal - should have behavior: 'smooth', but that causes a bug where selecting the first room doesn't scroll at all
                    window.scrollTo({ top: 0, left: 0 });
                  }
                }}
                errorMessage={
                  // Only show error for the last item selected
                  shouldShowErrorForRoom(availability.room.code) &&
                  props.createBookingError?.messageTranslationKey
                    ? getErrorTranslation(
                        props.createBookingError?.messageTranslationKey
                      )
                    : undefined
                }
                checkInDate={query.from}
                checkOutDate={query.to}
                roomOccupants={
                  getBookingGuestFromCount(basket.nextRoomToSelect)!
                }
                onDateRangeChange={props.onRequestDateRangeChange}
                lockRateToRequestCode={
                  basket.isRateSwitchingLocked
                    ? basket.selectedRequestRateCode
                    : undefined
                }
              />
            </React.Fragment>
          );
        })}
      </Stack>
    </HotelAvailabilitySearchResults>
  );
};

import { EnnismoreTheme } from '@/ui/theme/ennismore-theme.interface';

const fonts = {
  headline: 'Helvetica Neue, Helvetica, Arial, sans-serif',
  intro: 'Helvetica Neue, Helvetica, Arial, sans-serif',
  subheader: 'Helvetica Neue, Helvetica, Arial, sans-serif',
  body: 'Helvetica Neue, Helvetica, Arial, sans-serif',
};

export const twentyOneCTheme: EnnismoreTheme = {
  themeKey: '21c',
  logoUrl: '/brands/21c/images/logo.svg',
  faviconUrl: '/brands/21c/images/favicon.png',
  space: ['0px', '8px', '16px', '20px', '24px', '32px', '40px', '56px'],
  fonts,
  text: {
    heading1: {
      fontFamily: fonts.headline,
      fontSize: '32px',
      lineHeight: '32px',
      fontWeight: 300,
    },
    heading2: {
      fontFamily: fonts.subheader,
      fontSize: '56px',
      lineHeight: '64px',
      fontWeight: 300,
    },
    heading3: {
      fontFamily: fonts.subheader,
      fontSize: '40px',
      lineHeight: '48px',
    },
    heading4: {
      fontFamily: fonts.subheader,
      fontSize: '32px',
      lineHeight: '40px',
    },
    heading5: {
      fontFamily: fonts.subheader,
      fontSize: '32px',
      lineHeight: '32px',
      fontWeight: 300,
    },
    heading6: {
      fontFamily: fonts.subheader,
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: 700,
    },
    intro1: {
      fontFamily: fonts.intro,
      fontSize: '40px',
      lineHeight: '56px',
    },
    intro2: {
      fontFamily: fonts.intro,
      fontSize: '24px',
      lineHeight: '40px',
    },
    subheader1: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '32px',
    },
    subheader2: {
      fontFamily: fonts.subheader,
      fontSize: '18px',
      lineHeight: '32px',
    },
    subheader3: {
      fontFamily: fonts.subheader,
      fontSize: '14px',
      lineHeight: '24px',
    },
    bodyLarge: {
      fontFamily: fonts.body,
      fontSize: '20px',
      lineHeight: '32px',
    },
    bodyCopy: {
      fontFamily: fonts.body,
      fontSize: '18px',
      lineHeight: '28px',
    },
    bodySmall: {
      fontFamily: fonts.body,
      fontSize: '14px',
      lineHeight: '24px',
    },
    captions: {
      fontFamily: fonts.body,
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  colors: {
    white: 'white',
    textPrimary: '#3d3e3e',
    textSecondary: '#74767B',
    background: 'white',
    primary100: '#f7f7e1', // glen only - ignore
    primary200: 'white',
    primary300: '#74767B',
    primary400: '#e8e8e8', // hox only
    primary500: '#74767B',
    secondary100: '#f6f6f6',
    secondary200: '#d9d9d9',
    secondary300: '#74767B',
    secondary400: '#74767B',
    error: '#B00020',
  },
  buttons: {
    primary: {
      backgroundColor: '#C32830',
      fontFamily: fonts.subheader,
      fontSize: '20px',
      borderRadius: 0,
      fontWeight: 'normal',
    },
    secondary: {
      backgroundColor: 'transparent',
      fontFamily: fonts.subheader,
      border: '1px solid #ccc',
      fontSize: '20px',
      borderRadius: 0,
      fontWeight: 'normal',
      color: '#3d3e3e',
    },
  },
  keylines: {
    border: '1px solid #9fa1a4',
  },
  forms: {
    input: { fontSize: '16px' },
    label: {
      fontSize: '16px',
    },
  },
  fontFaceDefinitions: [],
  controls: {
    input: {
      borderRadius: '0px',
    },
  },
  componentProperties: {
    header: {
      wizard: {
        step: {
          opacity: 1,
          color: '#74767b',
          active: {
            color: '#3d3e3e',
          },
        },
      },
    },
    search: {
      results: {
        roomSpecColumns: 2,
        sidebarContainer: {
          rowGap: 'none',
          cancellationPolicy: {
            borderLeft: 'none',
            borderRight: 'none',
            borderTop: 'none',
          },
          hotelPerks: {
            borderLeft: 'none',
            borderRight: 'none',
            borderTop: 'none',
            borderBottom: 'none',
            mobile: {
              title: {
                fontSize: '24px',
              },
            },
          },
        },
      },
      collapsibleForm: {
        searchSummary: {
          expanded: {
            borderBottom: 'none',
          },
          mobile: {
            padding: '28px',
            title: {
              fontSize: '28px',
            },
          },
        },
        searchForm: {
          border: '1px solid #9fa1a4',
          borderTop: 'none',
        },
      },
    },
    addOnCard: {
      ctaLabel: {
        fontWeight: 500,
      },
      description: {
        opacity: 0.72,
      },
    },
    bookingManagement: {
      heroImage: {
        url: '/brands/21c/images/21c-mmb.png',
        alt: 'Two men enjoying a cheerful conversation on a rooftop terrace during a sunny day, with one holding a bottle and the other a glass of champagne. The man on the left wears a casual grey jacket over a shirt and dark trousers, while the man on the right is dressed in a vibrant, floral patterned blue suit with a golden scarf.',
      },
      pageWrapper: {
        login: {
          gridTemplateColumns: '50% 50%',
        },
        verify: {
          gridTemplateColumns: '50% 50%',
        },
      },
    },
    checkoutSidebar: {
      cancellationPolicy: {
        borderTop: 'none',
        borderBottom: 'none',
        borderLeft: 'none',
        borderRight: 'none',
      },
    },
    hotelPerk: {
      description: {
        color: '#74767B',
        opacity: 1,
      },
    },
    sidebarCards: {
      secondary: {
        borderRadius: '0px',
        padding: 'xs',
        backgroundColor: 'background',
        borderColor: 'primary300',
        color: 'textPrimary',
      },
      primary: {
        borderRadius: '0px',
        padding: 'l',
        backgroundColor: 'primary200',
        borderColor: 'white',
        color: 'textPrimary',
      },
    },
    banners: {
      backgroundColor: 'primary200',
      color: 'textPrimary',
    },
    staySummary: {
      color: '#74767B',
      title: {
        padding: '8px 0',
      },
    },
    cards: {
      primary: {
        border: '1px solid #9fa1a4',
        backgroundColor: 'white',
      },
    },
    bedroomCard: {
      description: {
        opacity: 0.8,
      },
      roomSpecItem: {
        fontWeight: 500,
        color: '#3D3E3E',
        value: {
          color: '#74767B',
          fontWeight: 400,
        },
      },
      roomSpecItemsList: {
        padding: '28px 0',
      },
    },
    page: {
      background: 'white',
    },
    confirmation: {
      header: {
        backgroundColor: '#f0f0f0',
      },
      headerImage: {
        margin: '0',
        width: '100%',
        height: '480px',
        paddingBottom: '48px',
        objectFit: 'cover',
      },
      bookingConfirmationContainer: {
        border: '1px solid #9fa1a4',
      },
      bookingNumberHeading: { fontSize: '24px' },
    },
  },
  icons: {
    BULLET: '/brands/gleneagles/icons/diamond-bullet.svg',
    TRAIN: '/brands/hoxton/icons/transport/train.svg',
    PLANE: '/brands/hoxton/icons/transport/plane.svg',
    SIZE: '/brands/hoxton/icons/room-icons/room-size-icon.svg',
    BED_SIZE: '/brands/hoxton/icons/room-icons/bed-size-icon.svg',
    CAPACITY: '/brands/hoxton/icons/room-icons/room-capacity.svg',
    DOG_FRIENDLY: '/brands/hoxton/icons/hox-hounds.svg',
    ROOM_WITH_VIEW: '/brands/hoxton/icons/room-icons/room-view-icon.svg',
    SNOOZE: '/brands/hoxton/icons/late-snooze.svg',
    WIFI: '/brands/hoxton/icons/wifi.svg',
    BREAKFAST: '/brands/hoxton/icons/breakfast-bag.svg',
    SWEET: '/brands/hoxton/icons/sweet.svg',
    DOG: '/brands/hoxton/icons/hox-hounds.svg',
    ADA: '/brands/hoxton/icons/accessibility.svg',
    GYM: '/brands/hoxton/icons/gym.png',
  },
  __temporary: {
    displayCarTransportOnNewLine: true,
  },
};
